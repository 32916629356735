import { AnalyticsService } from '@kfc-global/react-shared/analytics';
import events from '@kfc-global/react-shared/analytics/Register';

import { NOT_AVAILABLE, ANALYTICS_SHARED_CONSTANTS, DAYS } from 'common/constants/SharedConstants';
import { ChannelManager } from 'common/manager';
import {
  calculatePrice,
  getDataLayer,
  getUserType,
  getValueOrDefaultObject,
  getCouponDetails,
  isSupportedTenantCode,
  getTipSelection,
} from 'common/utilities/utils';
import { displayTipAmount } from 'common/utilities/amountCalcUtils';
import { getDayFromDate, getFormatFromUnix } from 'common/utilities/dayJsUtils';
/*********************************************************************
 * updatePaymentAnalytics
 * Params - cartData, currencyConversionFactor, tender, categories
 *********************************************************************/
export const updatePaymentAnalytics = (cartData, currencyConversionFactor, tender, categories) => {
  if (!isSupportedTenantCode()) {
    const itemsArray = [];
    cartData?.foodLines?.forEach((itemDetails, index) => {
      const { id = NOT_AVAILABLE, name = NOT_AVAILABLE } =
        categories?.find(cat => cat.products.find(p => p.name === itemDetails.item?.name)) || {};
      itemsArray.push({
        item_name: itemDetails?.item?.name || NOT_AVAILABLE,
        item_id: itemDetails?.item?.id || NOT_AVAILABLE,
        price: calculatePrice(itemDetails?.unitPrice, currencyConversionFactor),
        item_category: name,
        item_list_name: name,
        item_list_id: id,
        index: index + 1,
        quantity: itemDetails?.quantity || 0,
      });
    });
    paymentInfoClickAnalytics(tender?.name, itemsArray);
  }
};

export const logEvent = (name, content) => {
  // eslint-disable-next-line no-console
  AnalyticsService.registerEvent(name, content)?.then(() => console.log(`logEvent '${name}' successful`));
};

/*********************************************************************
 * guestContactDetailsClickAnalytics
 * Params - none
 *********************************************************************/
export const guestContactDetailsClickAnalytics = async () => {
  await AnalyticsService.registerEvent(
    events.guestContactDetails.name,
    {
      '%event%': 'guestContactDetails',
    },
    getDataLayer,
  );
};

/*********************************************************************
 * addContactInfoClickAnalytics
 * params - itemsArray
 *********************************************************************/
export const addContactInfoClickAnalytics = async itemsArray => {
  await AnalyticsService.registerEvent(
    events.addContactInfo.name,
    {
      data: {
        event: 'add_contact_info',
        ecommerce: {
          checkout_cart: {
            items: itemsArray,
          },
        },
      },
    },
    getDataLayer,
  );
};

/*********************************************************************
 * beginCheckoutClickAnalytics
 * params - itemsArray
 *********************************************************************/
export const beginCheckoutClickAnalytics = async itemsArray => {
  await AnalyticsService.registerEvent(
    events.beginCheckoutPwa.name,
    {
      data: {
        event: 'begin_checkout',
        ecommerce: {
          // checkout_cart: {
          items: itemsArray,
          // },
        },
      },
    },
    getDataLayer,
  );
};

export const beginCheckoutClickAnalyticsStep1 = async (itemsArray, userType, analyticsObject) => {
  await AnalyticsService.registerEvent(
    events?.beginCheckoutPwa?.name,
    {
      data: {
        event: ANALYTICS_SHARED_CONSTANTS.BEGIN_CHECKOUT,
        userType: userType,
        disposition: analyticsObject.disposition,
        store_id: analyticsObject.store_id,
        ecommerce: {
          items: itemsArray,
          coupon: analyticsObject?.coupon,
          value: analyticsObject?.total,
          currency: analyticsObject?.currency,
        },
      },
    },
    getDataLayer,
  );
};

export const RecommendedItemAnalytic = async (userType, itemsList, totalAmount) => {
  await AnalyticsService.registerEvent(
    events?.add_to_cart?.name,
    {
      data: {
        event: ANALYTICS_SHARED_CONSTANTS.ADD_TO_CART,
        userType,
        ecommerce: {
          currency: ANALYTICS_SHARED_CONSTANTS.AUD,
          value: totalAmount,
          items: itemsList,
        },
      },
    },
    getDataLayer,
  );
};

export const RemoveItemAnalytic = async (userType, itemsList, totalAmount) => {
  await AnalyticsService.registerEvent(
    events?.remove_from_cart?.name,
    {
      data: {
        event: ANALYTICS_SHARED_CONSTANTS.REMOVE_TO_CART,
        userType,
        ecommerce: {
          currency: ANALYTICS_SHARED_CONSTANTS.AUD,
          value: totalAmount,
          items: itemsList,
        },
      },
    },
    getDataLayer,
  );
};

/*********************************************************************
 * paymentInfoClickAnalytics
 * param - paymentType, itemsArray
 *********************************************************************/
export const paymentInfoClickAnalytics = async (paymentType, itemsArray) => {
  await AnalyticsService.registerEvent(
    events.paymentInfoClick.name,
    {
      data: {
        event: 'add_payment_info',
        paymentType: paymentType,
        ecommerce: {
          // checkout_cart: {
          items: itemsArray,
          // },
        },
      },
    },
    getDataLayer,
  );
};

/*********************************************************************
 * checkoutDetailsClickAnalytics
 * params - none
 *********************************************************************/
export const checkoutDetailsClickAnalytics = async () => {
  await AnalyticsService.registerEvent(
    events.checkoutClick.name,
    {
      data: {
        event: 'checkoutClick',
      },
    },
    getDataLayer,
  );
};
/*********************************************************************
 * purchaseOrderDetailsInfo
 * params - itemObj
 *********************************************************************/
export const purchaseOrderDetailsInfo = async params => {
  const {
    userType,
    itemObj,
    getBottleDepositChargeTotal,
    tipChargeType,
    tipCharge,
    currencySymbol,
    currencyConversionFactor,
    driverTippingChargeOption,
    orderingDateTime,
    storeId,
    storeName,
    state,
    payment_type,
    service,
  } = params;
  await AnalyticsService.registerEvent(
    events?.purchaseOrder?.name,
    {
      data: {
        event: 'purchase',
        userType: userType,
        tip_selection: tipChargeType
          ? getTipSelection({
              tipChargeType,
              tipCharge,
              currencySymbol,
              currencyConversionFactor,
              driverTippingChargeOption,
            })
          : '',
        tip_amount: tipChargeType ? displayTipAmount(tipCharge, currencySymbol, currencyConversionFactor) : '',
        bottle_deposit: getBottleDepositChargeTotal || NOT_AVAILABLE,
        storeID: storeId,
        storeName,
        payment_type,
        state,
        orderTime: getFormatFromUnix(orderingDateTime, 'HH:mm'),
        disposition: service?.toLowerCase(),
        purchase_day: DAYS[getDayFromDate(orderingDateTime, 'X')]?.toLowerCase(),
        ecommerce: itemObj || {},
      },
    },
    getDataLayer,
  );
};

/*********************************************************************
 * updatePurchaseOrderDetailsAnalytics
 * param - additionalChargeLines,    currencyCode,    currencyConversionFactor,    discountLines,    foodLines,    paymentReferences,    total,    taxTotal,
 *********************************************************************/
export const updatePurchaseOrderDetailsAnalytics = params => {
  const {
    additionalChargeLines,
    currencyConversionFactor,
    foodLines,
    paymentReferences,
    total,
    inclusiveTaxTotal,
    userStatus,
    bottleDepositChargeTotal,
    tipCharge,
    tipChargeType,
    subTotal,
    currencySymbol,
    currencyShortName,
    driverTippingChargeOption,
    store,
    storeName,
    tenders,
    postOrderDetails,
    orderingDateTime,
  } = params?.orderDetails || {};
  const { couponCode = '', discountTotal = '' } = getCouponDetails(params?.orderDetails);
  const { localized = false, loggedIn = false } = getValueOrDefaultObject(userStatus);
  const { storeId = '', address = {} } = store;
  const { state = '' } = address;
  const payment_type = tenders?.[0]?.type || NOT_AVAILABLE;
  const { service = '' } = postOrderDetails || {};
  const userType = getUserType(localized, loggedIn);
  const orderItemsList = [];
  const getBottleDepositChargeTotal = (bottleDepositChargeTotal / currencyConversionFactor).toFixed(2);
  foodLines?.forEach((foodLineItem, idx) => {
    const itemVariant = [];
    foodLineItem?.item?.modGrps?.forEach(group => {
      group?.modifiers.forEach(modifiers => {
        itemVariant.push(modifiers?.name);
      });
    });
    orderItemsList?.push({
      currency: currencyShortName,
      discount: discountTotal / currencyConversionFactor,
      item_name: foodLineItem?.item?.dname?.[0]?.value || NOT_AVAILABLE,
      item_sku: foodLineItem?.item?.id || NOT_AVAILABLE,
      value: foodLineItem?.item?.unitPrice / currencyConversionFactor || NOT_AVAILABLE,
      item_variant: itemVariant.join(', ') || NOT_AVAILABLE,
      quantity: foodLineItem?.quantity || NOT_AVAILABLE,
      item_category: foodLineItem?.item?.categoryName || NOT_AVAILABLE,
      item_list_name: foodLineItem?.item?.categoryName || NOT_AVAILABLE,
      item_list_id: foodLineItem?.item?.categoryId || NOT_AVAILABLE,
      index: idx,
    });
  });

  const transactionId =
    ChannelManager?.shouldGoogleSpotIgnoreOloFailure && ChannelManager?.transactionId
      ? ChannelManager?.transactionId
      : paymentReferences?.[0]?.transactionId ?? NOT_AVAILABLE;

  const paramsObj = {
    userType,
    itemObj: {
      affiliation: ANALYTICS_SHARED_CONSTANTS.ONLINE_STORE,
      transaction_id: transactionId,
      value: total ? total / currencyConversionFactor : NOT_AVAILABLE,
      tax: inclusiveTaxTotal ? inclusiveTaxTotal / currencyConversionFactor : NOT_AVAILABLE,
      shipping:
        additionalChargeLines?.[0]?.chargeType === 'delivery-charge'
          ? additionalChargeLines?.[0]?.amount / currencyConversionFactor
          : NOT_AVAILABLE,
      currency: currencyShortName,
      coupon: couponCode,
      items: orderItemsList || NOT_AVAILABLE,
    },
    getBottleDepositChargeTotal,
    tipChargeType,
    tipCharge,
    subTotal,
    currencySymbol,
    currencyConversionFactor,
    driverTippingChargeOption,
    storeId,
    storeName,
    state,
    payment_type,
    service,
    orderingDateTime,
  };
  purchaseOrderDetailsInfo(paramsObj);
};

/**
 * Method to set analytics items in the data layer object
 */
export const setLocalisationFinishAnalyticsData = async payload => {
  await AnalyticsService.registerEvent(
    events?.localisationFinish?.name,
    {
      '%event%': ANALYTICS_SHARED_CONSTANTS.LOCALISATION_FINISH,
      '%disposition%': payload?.disposition,
      '%postLocalisation%': 'true',
      '%storeID%': payload?.storeID,
      '%postCode%': payload?.postCode,
      '%localisationType%': ANALYTICS_SHARED_CONSTANTS.LOCALISATION_TYPE.MANUAL,
    },
    getDataLayer,
  );
};

/**
 * Method to set localisationStart items in the data layer object
 */
export const localisationStartAnalytics = async () => {
  await AnalyticsService.registerEvent(
    events?.localisationStart?.name,
    {
      '%event%': 'localisationStart',
    },
    getDataLayer,
  );
};

export const GuestContactDetailsClick = async () => {
  return guestContactDetailsClickAnalytics();
};

export const AddContactInfoClick = async itemsArray => {
  return addContactInfoClickAnalytics(itemsArray);
};

export const BeginCheckoutClick = async (itemsArray, analyticsObject) => {
  await AnalyticsService.registerEvent(
    events.beginCheckoutPwa.name,
    {
      data: {
        event: 'begin_checkout',
        userType: analyticsObject?.userType,
        disposition: analyticsObject.disposition,
        store_id: analyticsObject.store_id,
        ecommerce: {
          items: itemsArray,
          coupon: analyticsObject?.coupon,
          value: analyticsObject?.total,
          currency: analyticsObject?.currency,
        },
      },
    },
    getDataLayer,
  );
};

export const PaymentInfoClick = async (paymentType, itemsArray) => {
  return paymentInfoClickAnalytics(paymentType, itemsArray);
};

export const CheckoutDetailsClick = async () => {
  await AnalyticsService.registerEvent(
    events.checkoutClick?.name,
    {
      data: {
        event: 'checkoutClick',
      },
    },
    getDataLayer,
  );
};
export const onCheckoutAnalytics = async (userType, itemsArray) => {
  await AnalyticsService.registerEvent(
    events?.paymentInfoClick?.name,
    {
      data: {
        event: ANALYTICS_SHARED_CONSTANTS.ADD_PAYMENT,
        userType: userType,
        ecommerce: {
          // checkout_cart: {
          items: itemsArray,
          // },
        },
      },
    },
    getDataLayer,
  );
};

export const paymentAnalytics = async (paymentType, userType, itemsArray) => {
  await AnalyticsService.registerEvent(
    events?.paymentInfoClick?.name,
    {
      data: {
        event: ANALYTICS_SHARED_CONSTANTS.ADD_PAYMENT,
        userType: userType,
        paymentType: paymentType,
        ecommerce: {
          // checkout_cart: {
          items: itemsArray,
          // },
        },
      },
    },
    getDataLayer,
  );
};

export const loadContentCardAnalytics = async () => {
  await AnalyticsService.registerEvent(
    'messageWrapperLoaded',
    {
      '%event%': 'messageWrapperLoaded',
    },
    getDataLayer,
  );
};
